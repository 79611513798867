import { object, string, boolean, array, number, type InferType } from 'yup'

/**
 * Schema for the user information form
 *
 * This validator allows:
 * - Letters (A-Z, a-z) plus accents
 * - Spaces ( )
 * - Hyphens (-)
 *
 * It does not allow:
 * - Special characters (!@#$%^&*()_+)
 */
export const nameValidator = string()
  .trim()
  .transform((value) => {
    return value
      .split(/(?=[ -])|(?<=[ -])/g)
      .map((part: string) => {
        if (part === '-' || part === ' ') return part
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
      })
      .join('')
  })
  .required('This field is required')
  .max(200, 'Must be 200 characters or less')
  .matches(
    /^[\p{L}][\p{L} -]*[\p{L}]$|^[\p{L}]$/u,
    'Must contain only letters, spaces, or hyphens'
  )
  .matches(/^(?!.*--)/u, 'Only single hyphens are allowed')

export const userInformationSchema = object({
  firstname: nameValidator,
  lastname: nameValidator,
})
export type UserInformation = InferType<typeof userInformationSchema>

export const userSummarySchema = object({
  landingPage: number(), // 0 = User name input, 1 = Dashboard
  emailAddress: string().email(),
  firstname: string(),
  lastname: string(),
  charities: array().of(
    object({
      id: string(),
      name: string(),
      actions: number(),
    })
  ),
})
export type UserSummary = InferType<typeof userSummarySchema>

/**
 * Enum Regulator for the nominee assignment form
 */
export enum Regulator {
  PLACEHOLDER = 'placeholder',
  ENGLAND_WALES = 'englandWales',
  SCOTLAND = 'scotland',
  NORTHERN_IRELAND = 'northernIreland',
  OTHER = 'other',
  NONE = 'none',
}

export enum HumanisedRegulator {
  placeholder = 'Please select',
  englandWales = 'England & Wales',
  scotland = 'Scotland',
  northernIreland = 'Northern Ireland',
  other = 'Other',
  none = 'None',
}

/**
 * Schema for the nominee assignment form
 */
const validHmrcReference = /^(?:X|[A-Z]{2})\d{1,5}$/
export const nomineeAssignmentSchema = object({
  charityName: string()
    .trim()
    .required('Charity name is required')
    .max(200, 'Charity name must be 200 characters or less'),
  hmrcReference: string()
    .trim()
    .uppercase()
    .required('HMRC reference number is required')
    .matches(validHmrcReference, 'Invalid HMRC reference number'),
  regulator: string()
    .trim()
    .default(Regulator.PLACEHOLDER)
    .required('Regulator is required')
    .oneOf(
      Object.values(Regulator).filter(
        (value) => value !== Regulator.PLACEHOLDER
      ),
      'Regulator is required'
    ),
  regulatorNumber: string()
    .when('regulator', {
      is: (value: Regulator) =>
        [
          Regulator.ENGLAND_WALES,
          Regulator.SCOTLAND,
          Regulator.NORTHERN_IRELAND,
        ].includes(value),
      then: (schema: any) => schema.required('Regulator number is required'),
      otherwise: (schema: any) => schema.optional(),
    })
    .max(50, 'Regulator number must be 50 characters or less')
    .trim(),
  otherDetails: string()
    .when('regulator', {
      is: (value: Regulator) => [Regulator.OTHER].includes(value),
      then: (schema: any) => schema.required('Other details are required'),
      otherwise: (schema: any) => schema.optional(),
    })
    .trim()
    .max(160, 'Must be 160 characters or less'),
  website: string()
    .trim()
    .transform((value) => {
      // Remove trailing slash if present
      return value.endsWith('/') ? value.slice(0, -1) : value
    })
    .optional()
    .matches(/^https:\/\//, 'Must start with https://')
    .url('Must be a valid URL'),
  role: string()
    .trim()
    .optional()
    .max(100, 'Role must be 100 characters or less'),
})
export type NomineeAssignment = InferType<typeof nomineeAssignmentSchema>

/**
 * Schema for any email input
 */
const emailRegex =
  /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
export const emailSchema = object({
  email: string()
    .trim()
    .lowercase()
    .required('Email is required')
    .matches(emailRegex, 'Must be a valid email'),
})
export type Email = InferType<typeof emailSchema>

/**
 * Schema for the auth0 code input
 */
export const codeSchema = object({
  code: string().trim().required().length(6, 'Code must be 6 characters'),
})
export type Code = InferType<typeof codeSchema>

/**
 * Schema for Providers
 */
export const providerSchema = object({
  name: string().required(),
  slug: string().required(),
  logo: object({
    src: string().required(),
    style: object().optional(),
  }).required(),
  translationKey: string().optional(),
  brandColor: string().optional(),
  inputPlaceholder: string().optional(),
  inputMask: object({
    mask: string().required(),
    definitions: object().optional(),
    prepareChar: object().optional(),
  }).optional(),
  inputValidation: object().optional(),
  upperCase: boolean().optional(),
  informationShareList: object({
    swiftaidShareWithProvider: array().of(string()).required(),
    providerShareWithSwiftaid: array().of(string()).required(),
  }).optional(),
  helpPage: string().optional(),
})
export type Provider = InferType<typeof providerSchema>

export enum NotificationSeverity {
  Information = 'information',
  Warning = 'warning',
  Error = 'error',
}
export enum NotificationCategory {
  Settings = 'settings',
  DonationSources = 'donationSources',
  Declaration = 'declaration',
  Support = 'support',
  Billing = 'billing',
  Link = 'link',
}
export type Notification = {
  id: string
  time: string
  severity: NotificationSeverity
  category?: NotificationCategory
  message: string
  description: string
  url?: string
}

export enum LoginMethod {
  CODE = 'code',
  LINK = 'link',
}

export type Fee = {
  amount: string
  type: 'pc' | 'fixed'
}

export enum DonationSourceState {
  SETUP = 'setup',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  ACTIVE = 'active',
}

export type DonationSource = {
  id: string
  friendlyName: string
  charityDisplayName: string
  matchRule: any
  from: string
  until: string
  amount: string
  fees: Fee[]
  totalGiftAid: number
  totalDonations: number
  state: DonationSourceState
  provider: string
  providerReference: string
}

export enum SubmissionFileType {
  CSV = 'text/csv',
  ODS = 'application/vnd.oasis.opendocument.spreadsheet',
}

export enum SubmissionFileExtension {
  CSV = 'csv',
  ODS = 'ods',
}

export enum DataCollectionEventType {
  GAF_TEMPLATE_DOWNLOAD = 'GAF_TMPL_DL',
  GAF_SUBMISSION_UPLOAD = 'GAF_SUB_UL',
  CREATE_CHARITY = 'CHARITY_CREATE',
}

export enum GafSubmissionState {
  CHECKING = 'checking',
  READY = 'ready',
  PROCESSED = 'processed',
  CANCELLED = 'cancelled',
}

export type GafSubmission = {
  submissionId: string
  firstActivity: string
  firstFileName: string
  firstDonationCount: number | null
  lastDonationCount: number | null
  state: GafSubmissionState
}
